import React from "react";
//import PropTypes from "prop-types";
//import "bootstrap/dist/css/bootstrap.min.css";

export default class Contact extends React.Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <div id="my-contact" className="container text-center my-5">
        <h1 id="contact">Contact Us</h1>
        <br />
        <h4 id="contact1">Email us: firstcostconceptz@gmail.com</h4>
        <h4 id="contact1">Call us: 23480-3328-7183; 23480-2227-2600</h4>

        <div
          style={{
            padding: "10px",
            background: "url(../images/image8x.jpg),no-repeat top center"
          }}
        >
          <form
            className="clue"
            action="https://firstcostconceptz@gmail.com"
            method="POST"
          >
            <div
              id="map-container"
              className="rounded z-depth-1-half map-container"
              style={{ height: "400px" }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7928.4926141671685!2d3.2971896293330447!3d6.490464287123995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sPlot+3578%2C+Ossi+Uzoamaka+street%2C+Lakeview+Estate%2C+Phase+1%2C+Amuwo+Odofin%2C+Lagos.!5e0!3m2!1sen!2sng!4v1549734278821"
                title="This is a unique title"
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
              />
            </div>
            <br />

            <div className="form-group">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 mx-auto">
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-lg"
                    id="name"
                    placeholder="Name"
                  />
                </div>
              </div>
            </div>

            <div className="form-group hidden">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 mx-auto">
                  <input
                    type="email"
                    name="_replyto"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    placeholder="Your email"
                  />
                </div>
              </div>
            </div>
            <div className="form-group hiddenRight">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 mx-auto">
                  <textarea
                    name="message"
                    className="form-control form-control-lg"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Write your message..."
                  />
                </div>
              </div>
            </div>
            <div className="row text-md-right text-sm-center">
              <div className="col-12 col-sm-12 col-md-6 mx-auto">
                <button type="submit" className="btn btn-primary mb-2 hidden">
                  Submit
                </button>
              </div>
            </div>
            <br />
          </form>
        </div>
      </div>
    );
  }
}
