let resumeData = {
  //imagebaseurl: "https://rbhatia46.github.io/",
  imagebaseurl: "images/portfolio/fccimage.jpg",
  name: "First Cost Conceptz",
  role: "BUILDERS",
  linkedinId: "Your LinkedIn Id",
  skypeid: "Your skypeid",
  roleDescription:
    "We provide quality services and project delivery beyond the expectation and demand of the sector with our experienced personnel and mordern infrastructure to make First Cost Conceptz an outstanding brand in the sector",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/stephen-omisore-b90a92188/",
      className: "fa fa-linkedin"
    },
    {
      name: "facebook",
      url: "https://facebook.com/firstcostconceptz",
      className: "fa fa-facebook"
    },
    {
      name: "twitter",
      url: "http://twitter.com/@OmisoreStaphen",
      className: "fa fa-twitter"
    }
  ],
  aboutme:
    "First Cost Conceptz has always aim at providing qualitative and affordable  services for its customers and has aligned with the changing trend in technological innovation for better performance. We render our Professional services for both Private individuals and Corporate Organisations.",
  address: "Lakeview Phase 1, Lagos",
  website: "https://www.firstcostconceptz.com",
  CoreValues: [
    {
      UName: "Our Key Markets",
      specialization:
        "Our operational service cater to a wider spectrum of construction needs in both private and public sectors.Our work portfolio has record of project success in the Residential and Commercial spaces and working with clients across different sector and with varying budgets."
      //MonthOfPassing: "Aug",
      //YearOfPassing: "2020",
      //Achievements: "Some Achievements"
    },
    {
      UName: "Our Core-Values",
      specialization:
        "We combine quality workmanship, superior knowledge and low prices to provide you with service unmatched by our competitors."
      // MonthOfPassing: "Jan",
      // YearOfPassing: "2018",
      // Achievements: "Some Achievements"
    }
  ],
  work: [
    {
      CompanyName: "Over 10 Years Experience",
      specialization:
        "At SOV We have established a strong reputation within our market by executing projects within the time frame and budgets while adhering to strict quality control measure. Our corporate integrity, financial strength and outstanding record of performance pre-qualifies us for projects in situation where smaller and less diversify contractors are unable to do so. Our success results from our proven ability to manage large and complex projects within aggressive fast- track schedule, elaborate design and advanced system while providing accurate budgeting and strict quality control",
      MonthOfLeaving: "Jan",
      YearOfLeaving: "2018",
      Achievements: "Some Achievements"
    },
    {
      CompanyName: "Team Work",
      specialization:
        "We have the experience, personel and resources to make the project run smoothly. We can ensure a job is done on time. Work with us involve a carefully planned series of steps, centered around a schedule we stick to and daily communication.",
      MonthOfLeaving: "Jan",
      YearOfLeaving: "2018",
      Achievements: "Some Achievements"
    }
  ],
  skillsDescription: "Our skills..",
  skills: [
    {
      skillname: "Project Design and other Pre-Contract Activites"
    },
    {
      skillname: "Project Management"
    },
    {
      skillname: "Building Construction"
    }
  ],
  portfolio: [
    {
      name: "project1",
      description: "mobileapp",
      imgurl: "images/portfolio/project1.jpg"
    },
    {
      name: "project2",
      description: "mobileapp",
      imgurl: "images/portfolio/project4.jpg"
    },
    {
      name: "project3",
      description: "mobileapp",
      imgurl: "images/portfolio/project2.jpg"
    },
    {
      name: "project4",
      description: "mobileapp",
      imgurl: "images/portfolio/project3.jpg"
    },
    {
      name: "Project",
      description: "Designs",
      imgurl: "images/portfolio/Hsmodel2f.jpg"
    },
    {
      name: "Project",
      description: "Designs",
      imgurl: "images/portfolio/handModel2.jpg"
    },
    {
      name: "Project",
      description: "Designs",
      imgurl: "images/portfolio/Structure1c.jpg"
    },
    {
      name: "Project",
      description: "Designs",
      imgurl: "images/portfolio/Design2.jpg"
    }
  ],
  testimonials: [
    {
      description: "Projects Design",
      name:
        "Our creative design initiative is second to none, when it comes to making choice of a project designer, we are up to date in our creative services."
    },
    {
      description: "Building Construction",
      name:
        "We provide quality services and project delivery beyond the expectation and demand of the sector with our experienced personnel and technological infrastructure and to make Saint Omio Ventures an outstanding brand in the sector."
    },
    {
      description: "Interior Decoration",
      name:
        "When it comes to choosing an interior designer or decorator to transform the interior of your home, quality and trust should never be compromised. Working with a professional is an absolute must. With over 10years’ experience and a real focus on customer satisfaction, you can rely on us for your next Interior design or decorations. Our installations are carried out by trained technicians with high professional standards. We are always on time and on budget."
    },
    {
      description: "Landscapping",
      name:
        "We undertake divers range of Landscapping projects, We have wide range of construction equipment for our use on any of our projects, others would be leased from our sister company when required."
    },
    {
      description: "Building Renovation",
      name:
        "When it comes to building renovation, quality and trust should never be compromised. Working with a professional is an absolute must.  You can rely on us for your next building renovation works. Our operations are carried out by fully trained personnel to the highest professional standards."
    }
  ]
};

export default resumeData;
